<template>
  <div>
    <div class="videoContainer">
      <vue-plyr
        ref="plyr"
        :options="options"
      >
        <video id="player" crossorigin />
      </vue-plyr>
      <div class="vodDetails">
        <span>{{ vodData.title }}</span>
      </div><span style="color: #7367f0">{{ formatDate(vodData.created_at) }}</span>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import axios from 'axios'

export default {
  props: ['id'],
  data() {
    return {
      vodData: {},
      options: { controls: ['play-large', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'] },
    }
  },
  async beforeMount() {
    this.vodData = await (await axios.get(`https://archive-api.brimecdn.com/ords/admin/api/vod/${this.id}`)).data.items[0]
  },
  mounted() {
    const video = document.querySelector('#player')
    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(`https://clips.brimecdn.com/jakenbake/vods/${this.id}/index-dvr.m3u8`)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play()
      })
    }
    console.log(this.$refs.plyr)
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
      }
      return new Date(date).toLocaleDateString(undefined, options)
    },
  },
}
</script>

<style scoped>
.videoContainer{
max-width: 1400px !important;
margin: auto;
margin-top: -25px;
}
.vodDetails{
margin-top: 0.75rem;
font-weight: bold;
font-size: 18px;
    display: flex;
    justify-content: space-between;
}
</style>
<style>
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}

</style>
